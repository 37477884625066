import { WallEntity } from './WallEntity';

export class WallFrameEntity {
  originCoord = [];
  targetCoord = [];
  scene = null;
  renderer = null;
  raycaster = null;
  camera = null;
  planeMesh = null;
  walls = [];

  constructor(originCoord, targetCoord, scene, renderer, raycaster, camera, planeMesh) {
    this.originCoord = originCoord;
    this.targetCoord = targetCoord;
    this.scene = scene;
    this.renderer = renderer;
    this.raycaster = raycaster;
    this.camera = camera;
    this.planeMesh = planeMesh;

    this.createWalls();
  }

  createWalls() {
    const topLeft = this.originCoord;
    const topRight = [this.targetCoord[0], this.originCoord[1]];
    const bottomRight = this.targetCoord;
    const bottomLeft = [this.originCoord[0], this.targetCoord[1]];

    this.walls.push(new WallEntity(topLeft, topRight, this.scene, this.renderer, this.raycaster, this.camera, this.planeMesh));
    this.walls.push(new WallEntity(topRight, bottomRight, this.scene, this.renderer, this.raycaster, this.camera, this.planeMesh));
    this.walls.push(new WallEntity(bottomRight, bottomLeft, this.scene, this.renderer, this.raycaster, this.camera, this.planeMesh));
    this.walls.push(new WallEntity(bottomLeft, topLeft, this.scene, this.renderer, this.raycaster, this.camera, this.planeMesh));
  }

  updateFrameCoords(targetCoord) {
    this.targetCoord = targetCoord;

    const topLeft = this.originCoord;
    const topRight = [this.targetCoord[0], this.originCoord[1]];
    const bottomRight = this.targetCoord;
    const bottomLeft = [this.originCoord[0], this.targetCoord[1]];

    this.walls[0].updateCoords(topLeft, topRight);
    this.walls[1].updateCoords(topRight, bottomRight);
    this.walls[2].updateCoords(bottomRight, bottomLeft);
    this.walls[3].updateCoords(bottomLeft, topLeft);
  }
}

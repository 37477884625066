import * as THREE from 'three';

export class WallEntity {

  originCoord = []
  targetCoord = []
  scene = null
  renderer = null
  raycaster = null
  camera = null
  planeMesh = null

  wallObj = null
  finalized = false

  constructor(originCoord, targetCoord, scene, renderer, raycaster, camera, planeMesh) {
    this.originCoord = originCoord
    this.targetCoord = targetCoord

    this.scene = scene
    this.renderer = renderer
    this.raycaster = raycaster
    this.camera = camera
    this.planeMesh = planeMesh
    
    this.drawWall()
  }

  get finalized() {
    return this.finalized
  }

  updateCoords(originCoord, targetCoord) {
    this.originCoord = originCoord
    this.targetCoord = targetCoord
    this.drawWall()
  }

  updateTargetCoord(targetCoord) {
    this.targetCoord = targetCoord
    this.drawWall()
  }

  drawWall() {
    this.scene.remove(this.wallObj);

    const points = [];

    let unprojPosOrigin = this.getUnprojectPos(this.originCoord[0], this.originCoord[1]);
    points.push(new THREE.Vector3(unprojPosOrigin.x, unprojPosOrigin.y, 0));

    let unprojPosTarget = this.getUnprojectPos(this.targetCoord[0], this.targetCoord[1]);
    points.push(new THREE.Vector3(unprojPosTarget.x, unprojPosTarget.y, 0));

    const width = 0.001; 
    const length = points[1].distanceTo(points[0]) + width;

    const center = new THREE.Vector3().addVectors(points[0], points[1]).multiplyScalar(0.5);
    const angle = Math.atan2(points[1].y - points[0].y, points[1].x - points[0].x);
    const geometry = new THREE.PlaneGeometry(length, width);
    const material = new THREE.MeshBasicMaterial({ color: 0x4d4d4d, side: THREE.DoubleSide });
    this.wallObj = new THREE.Mesh(geometry, material);
    this.wallObj.position.copy(center);
    this.wallObj.rotation.z = angle;
    this.scene.add(this.wallObj);
  }

  getUnprojectPos(eventPosX, eventPosY) {
    var mouse = new THREE.Vector2(); // create once

    let elem = this.renderer.domElement
    mouse.x = (eventPosX / elem.clientWidth) * 2 - 1;
    mouse.y = -(eventPosY / elem.clientHeight) * 2 + 1;

    this.raycaster.setFromCamera( mouse, this.camera );
    let intersects = this.raycaster.intersectObject( this.planeMesh )

    if (intersects.length > 0) return {x: intersects[0].point.x, y: intersects[0].point.y}
    else return null
  }

  finalize() {
    
  } 
}